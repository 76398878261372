export const environment = {
  production: true,
  name: 'prod',
  useEmulators: false,
  firebase: {
    config: {
      apiKey: 'AIzaSyDE6nFCWc0eSnT3QVv43fare02pNADWoQo',
      authDomain: 'byn-prod.firebaseapp.com',
      projectId: 'byn-prod',
      storageBucket: 'byn-prod.appspot.com',
      messagingSenderId: '129702566400',
      appId: '1:129702566400:web:30aabc9f373f0888bc16e5',
      measurementId: 'G-QKBY75HGQD'
    },
    verificationRedirectUrl: 'https://merchant.storebloc.eu/'
  },
  region: 'europe-west3',
  recaptchaKey: '6LeWG3UoAAAAABlWuxAUGMc8IYe3Lq6RUxfZWNqY',
  swiftPayRedirectUrl: 'https://api.pay.live.swiftpay.ph',
  buildNumber: '20241015.8',
  appUrl: '',
  apiUrl: 'https://europe-west3-byn-prod.cloudfunctions.net/api'
};
